import React, { useEffect, useState } from "react";
import { ESteps, stepState } from "@state/step";
import { Route, Switch } from "@components/router";
import Login from "@routes/login/Login";
import Welcome from "@routes/welcome/Welcome";
import Business from "@routes/business";
import Step_5 from "@routes/step-5";
import Step_9_30 from "@routes/step-9-30";
import Step_11 from "@routes/step-11";
import Step_12_30 from "@routes/step-12-30";
import Step_13_30 from "@routes/step-13-30";
import Step_15 from "@routes/step-15";
import Step_18 from "@routes/step-18";
import Step_18_15 from "@routes/step-18-15";
import Step_19 from "@routes/step-19";
import WelcomeBackModal from "@components/welcome-back-modal/WelcomeBackModal";
import Checkpoint from "@routes/checkpoints/Checkpoint";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Distribution from "@routes/step-10";
import Distribution2 from "@routes/step-15-15";
import { Helmet } from "react-helmet"
import { authState } from "@state/auth";
import api from "@api";

export default () => {

  const [page, setStep]= useRecoilState(stepState);
  const [authValue, setAuthValue] = useRecoilState(authState);
  useEffect(() => {
    if (authValue.gameid && authValue.userid) {
      api
        .postCheckGame(authValue)
        .then(({ data }) => {
          setStep(data.step as ESteps);
        })
        .catch(() => {
          setAuthValue({
            gameid: "",
            userid: ""
          });
          setStep(ESteps.LOGIN);
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
          <title>Trucks 'n' Tricks</title>
          <meta name="icon" href="/public/favicon.png" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Switch value={`${page}`}>
        <Route
          key={`${ESteps.LOGIN}`}
          routeValue={`${ESteps.LOGIN}`}
          Component={Login}
        />
        <Route
          key={`${ESteps.WELCOME}`}
          routeValue={`${ESteps.WELCOME}`}
          Component={Welcome}
        />
        <Route
          key={`${ESteps.BUSINESS_EXPLAIN}`}
          routeValue={`${ESteps.BUSINESS_EXPLAIN}`}
          Component={Business}
        />
        <Route
          key={`${ESteps.CHECKPOINT_1}`}
          routeValue={`${ESteps.CHECKPOINT_1}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_2}`}
          routeValue={`${ESteps.CHECKPOINT_2}`}
          Component={Checkpoint}
        />

        <Route
          key={`${ESteps.CHECKPOINT_3}`}
          routeValue={`${ESteps.CHECKPOINT_3}`}
          Component={Checkpoint}
        />

        <Route
          key={`${ESteps.CHECKPOINT_4}`}
          routeValue={`${ESteps.CHECKPOINT_4}`}
          Component={Checkpoint}
        />

        <Route
          key={`${ESteps.CHECKPOINT_5}`}
          routeValue={`${ESteps.CHECKPOINT_5}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_6}`}
          routeValue={`${ESteps.CHECKPOINT_6}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_7}`}
          routeValue={`${ESteps.CHECKPOINT_7}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_8}`}
          routeValue={`${ESteps.CHECKPOINT_8}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_9}`}
          routeValue={`${ESteps.CHECKPOINT_9}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_10}`}
          routeValue={`${ESteps.CHECKPOINT_10}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.CHECKPOINT_11}`}
          routeValue={`${ESteps.CHECKPOINT_11}`}
          Component={Checkpoint}
        />
        <Route
          key={`${ESteps.PRODUCTION_1}`}
          routeValue={`${ESteps.PRODUCTION_1}`}
          Component={Step_5}
        />
        <Route
          key={`${ESteps.BATCH_1}`}
          routeValue={`${ESteps.BATCH_1}`}
          Component={Step_9_30}
        />
        <Route
          key={`${ESteps.PRODUCTION_2}`}
          routeValue={`${ESteps.PRODUCTION_2}`}
          Component={Step_11}
        />
        <Route
          key={`${ESteps.PRELIMINARY_SALES_RESULTS}`}
          routeValue={`${ESteps.PRELIMINARY_SALES_RESULTS}`}
          Component={Step_12_30}
        />
        <Route
          key={`${ESteps.ADJUSTMENTS_1}`}
          routeValue={`${ESteps.ADJUSTMENTS_1}`}
          Component={Step_13_30}
        />
        <Route
          key={`${ESteps.BATCH_2}`}
          routeValue={`${ESteps.BATCH_2}`}
          Component={Step_15}
        />
        <Route
          key={`${ESteps.RESULTS}`}
          routeValue={`${ESteps.RESULTS}`}
          Component={Step_18}
        />
        <Route
          key={`${ESteps.ADJUSTMENTS_2}`}
          routeValue={`${ESteps.ADJUSTMENTS_2}`}
          Component={Step_18_15}
        />
        <Route
          key={`${ESteps.END_OF_DAY}`}
          routeValue={`${ESteps.END_OF_DAY}`}
          Component={Step_19}
        />
        <Route
          key={`${ESteps.DISTRIBUTION_1}`}
          routeValue={`${ESteps.DISTRIBUTION_1}`}
          Component={Distribution}
        />
        <Route
          key={`${ESteps.DISTRIBUTION_2}`}
          routeValue={`${ESteps.DISTRIBUTION_2}`}
          Component={Distribution2}
        />
      </Switch>
      {/* <QuitModal /> */}
      {/* <WelcomeBackModal /> */}
    </>
  );
};
